import { find, isEmpty } from 'lodash';
import React, { useEffect } from 'react';
import { navigate } from 'gatsby';
import { connect, ConnectedProps, useDispatch } from 'react-redux';
import { Spinner } from 'react-activity';
import { VideoPlayer } from 'src/components/shared';
import { ContentSelectors, IState } from '@pvolve/sdk/src/redux/selectors';
import PvolveActions from '@pvolve/sdk/src/app/actions';
import PvolveSelectors from '@pvolve/sdk/src/app/selectors';
import Selectors from 'src/state/root-selectors';
import Waiver from 'src/components/video/Waiver';
import Actions from 'src/state/root-actions';

import 'src/styles/video-player.scss';
import RedirectToZelda from 'src/components/redirect-to-zelda';

export interface VideoPageParams {
    slug: string;
}

export interface VideoPageProps {
    params: VideoPageParams;
}

const connector = connect((state: IState) => {
    return {
        workouts: ContentSelectors.workout.list(state),
        entitled: Selectors.auth.entitled(state),
        historyIndex: PvolveSelectors.workouts.resumableHistory(state),
        historyLoading: PvolveSelectors.workouts.historyLoading(state),
        userAttributes: Selectors.account.attrs(state),
    };
});

const VideoPage = ({
    entitled,
    historyIndex,
    historyLoading,
    params,
    workouts,
    userAttributes,
}: VideoPageProps & ConnectedProps<typeof connector>) => {
    return <RedirectToZelda />
    
    const dispatch = useDispatch();
    const { slug } = params;
    const loading = entitled ? historyLoading || isEmpty(workouts) : isEmpty(workouts);
    const workout = find(workouts, (w) => w.fields.slug === slug);
    const history = workout
        ? find(historyIndex, (h) => h.workout_id === workout.sys.id)
        : undefined;

    const acceptedWaiver = !!userAttributes?.user?.object?.waiver;

    useEffect(() => {
        dispatch(Actions.account.getAllAttrs.trigger());
        dispatch(PvolveActions.workouts.loadHistory.trigger());
    }, [dispatch]);

    if (loading) {
        return (
            <div className="video-placeholder-container">
                <Spinner size={40} speed={0.75} />
            </div>
        );
    }

    if (!workout) {
        navigate('/404');
        return <div className="video-placeholder-container" />;
    }

    if (entitled && !acceptedWaiver) {
        return <Waiver workout_id={workout?.sys.id} />;
    }

    return <VideoPlayer entitled={entitled} history={history} videoId={workout?.sys.id} />;
};

export default connector(VideoPage);
